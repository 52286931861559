import DOMPurify from 'dompurify';
import { UnknownObject } from '~/types';

const allowIframeOptions = {
  ADD_TAGS: ['iframe'],
  ADD_ATTR: ['target', 'frameborder', 'allow', 'allowfullscreen'],
};

interface SanitizedHtmlProps {
  html: string;
  className?: string;
  style?: UnknownObject;
  testid?: string;
  allowIframe?: boolean;
}

const SanitizedHtml = ({
  html = '',
  className = '',
  style = {},
  testid = '',
  allowIframe = false,
}: SanitizedHtmlProps): JSX.Element => {
  const purifyOptions = allowIframe ? allowIframeOptions : {};
  const sanitizedContent = DOMPurify.sanitize(html, purifyOptions);
  return (
    <div
      className={className}
      style={style}
      dangerouslySetInnerHTML={{
        __html: sanitizedContent,
      }}
      data-testid={testid}
    />
  );
};

export default SanitizedHtml;
